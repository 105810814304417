const version = '1.4.0.0';
const postLogOutUri = 'https://dev-appshub.coolsystemsselect.com';  //TODO: UM UI url with domain name //https://Dev.Appshub.co2ol.com   //http://localhost:4200 //https://dev.d2zxmr9q9sq50a.amplifyapp.com
const apiUri = 'https://devapi-appshub.coolsystemsselect.com';  //TODO: //https://DevApi.Appshub.co2ol.com  //http://ccr-appshubapi-qa.eu-central-1.elasticbeanstalk.com
const authModuleVer = '2.1.6';  //TODO: 
const storageBaseUri = 'https://staticfilesappshubdev.coolsystemsselect.com/';  //TODO: S3 bucket url //https://d24nvz8cifem7s.cloudfront.net/

export const appConfig = {
  api: {
    development: apiUri,
    postLogoutRedirectUri: postLogOutUri,
    encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
    },
    version: version,
    authVersion: authModuleVer,
    authSettings: {
      azureIss: "Azure Iss",  //TODO:
    //   awsIss: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_OhoDEPXUA" //TODO: Cognito Issuer
      awsIss: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_BcZOqoFML" //TODO:AJ  PROD COGNITO POOL ID
    },
    blobUrl: storageBaseUri + 'logo/',
    emailUrl: storageBaseUri + 'email/',
    storageUrl: storageBaseUri,
    loginConfig: {
      apiUrl: apiUri + '/',
      uiUrl: postLogOutUri,
      postLogOutUri: postLogOutUri,
      redirectAppUri: "https://devauth-appshub.coolsystemsselect.com",  //TODO: Hostable auth module UI url  //https://DevAuth.Appshub.co2ol.com   //https://dev.d1rdukx7ih0ak3.amplifyapp.com
      // redirectAppUri: "http://localhost:4201/",  //TODO: Hostable auth module UI url  //https://DevAuth.Appshub.co2ol.com   //https://dev.d1rdukx7ih0ak3.amplifyapp.com
    //   appClientId: '4lvfhehheh6cm5rb1ge6plpoo3' //TODO: double check 
      appClientId: 'g93e8ork1147c6tktj8hlf779' //TODO:AJ PROD ClientID 
    }
  }
}

export default appConfig


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//QA

// const version = '1.4.0.0';
// const postLogOutUri = 'https://qa-appshub.coolsystemsselect.com';
// const apiUri = 'https://qaapi-appshub.coolsystemsselect.com';
// const authModuleVer = '2.1.9';
// const storageBaseUri = 'https://staticfilesappshubqa.coolsystemsselect.com/';

// export const appConfig = {
//   api: {
//     development: apiUri,    
//     postLogoutRedirectUri: postLogOutUri,
//     encryption: {
//       IV: "8080808080808080",
//       Key: "8080808080808080"
//     },
//     version: version,
//     authVersion: authModuleVer,
//     authSettings: {
//       azureIss: "Azure Iss",
//       awsIss: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_OhoDEPXUA"
//     },
//     blobUrl: storageBaseUri + 'logo/',
//     emailUrl:storageBaseUri+'email/',
//     storageUrl: storageBaseUri,    
//     loginConfig: {
//       apiUrl: apiUri + '/',
//       uiUrl: postLogOutUri,     
//       postLogOutUri: postLogOutUri,
//       redirectAppUri: "https://devauth-appshub.coolsystemsselect.com",
//       appClientId: '4lvfhehheh6cm5rb1ge6plpoo3'
//     }
//   }
// }

// export default appConfig

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PROD
// const version = '1.4.0.0';
// const postLogOutUri = 'https://appshub.coolsystemsselect.com';
// const apiUri = 'https://api-appshub.coolsystemsselect.com';
// const authModuleVer = '2.1.9';
// const storageBaseUri = 'https://staticfilesappshub.coolsystemsselect.com/';

// export const appConfig = {
//   api: {
//     development: apiUri,    
//     postLogoutRedirectUri: postLogOutUri,
//     encryption: {
//       IV: "8080808080808080",
//       Key: "8080808080808080"
//     },
//     version: version,
//     authVersion: authModuleVer,
//     authSettings: {
//       azureIss: "Azure Iss",
//       awsIss: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_BcZOqoFML"
//     },
//     blobUrl: storageBaseUri + 'logo/',
//     emailUrl:storageBaseUri+'email/',
//     storageUrl: storageBaseUri,    
//     loginConfig: {
//       apiUrl: apiUri + '/',
//       uiUrl: postLogOutUri,     
//       postLogOutUri: postLogOutUri,
//       redirectAppUri: "https://auth-appshub.coolsystemsselect.com",
//       appClientId: 'g93e8ork1147c6tktj8hlf779'
//     }
//   }
// }

// export default appConfig